<template>
  <Modal title="Crear Area" ref="modal_create">
    <template v-slot:body>
      <form @submit.prevent="store">
        <form-profession v-model="form"  />
      </form>
    </template>
  </Modal>
</template>

<script>
import FormProfession from "@/views/catalogs/profession/FormProfession";
import ProfessionService from "@/services/ProfessionService";

export default {
  components: {FormProfession},
  data() {
    return {
      form: {
        area_id: null,
        name: null,
        verified: true,
      }
    }
  },
  methods: {
    open() {
      this.form = { name: null, verified: true, area_id: null }
      this.$refs.modal_create.launch();
    },
    store(){
      ProfessionService.store(this.form).then(response => {
        this.$showSuccess("Se ha guardado correctamente");
        this.$refs.modal_create.close();
        this.$emit('onFinished');
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
