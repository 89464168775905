import axiosIns from "@/libs/axios";

export default class ProfessionService {
    static get() {
        return axiosIns.get('/professions')
    }

    static store(data){
        return axiosIns.post('/professions', data)
    }

    static update(data){
        return axiosIns.post('/professions/verified-single', data)
    }
}
