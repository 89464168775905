import axiosIns from "@/libs/axios";

export default class AreaService {
    static get() {
        return axiosIns.get('/areas')
    }

    static store(data){
        return axiosIns.post('/areas', data)
    }

    static update(data){
        return axiosIns.post('/areas/verified-single', data)
    }

    static updateSingle(id, data){
        return axiosIns.put('/areas/'+id, data)
    }

    static importData(data) {
        return axiosIns.post("/import/data",data)
    }
}
